import Menu from "./Menu";

(function ($) {
  const menu = new Menu();
  /** variables */
  $("#billing_Acquista_come option:first").text("Seleziona...");
  $("#billing_first_name").attr("placeholder", "Inserisci il nome");
  $("#billing_last_name").attr("placeholder", "Inserisci il cognome");
  $("#billing_email").attr("placeholder", "Inserisci la tua mail");
  $("#billing_phone").attr(
    "placeholder",
    "Inserisci il tuo numero di telefono"
  );
  $("#billing_postcode").attr("placeholder", "Inserisci il Cap");
  $("#billing_city").attr("placeholder", "Inserisci la città");
  $("#order_comments").attr("placeholder", "Inserisci le note dell'ordine");

  $("#billing_company").val("");
  $("#billing_company").attr("placeholder", "Inserisci la ragione sociale");

  $("input.job").each(function () {
    $(this).keyup(function (e) {
      let inputJob = e.target;
      let inputJobVal = $(this);
      let datafetch = inputJob.nextElementSibling;
      let datafetchId = $(this).data("fetch");

      if ($(this).val().length > 2) {
        datafetch.classList.add("active");

        jQuery.ajax({
          url: mcData.ajaxurl,
          type: "post",
          data: { action: "data_fetch", keyword: $(this).val() },
          success: function (data) {
            $(`#${datafetchId}`).html(data);

            $(`#${datafetchId} ul li`).each(function () {
              $(this).click(function (e) {
                inputJobVal.val($(this).text());
                datafetch.classList.remove("active");
              });
            });
          },
        });
      } else {
        datafetch.classList.remove("active");
      }
    });
  });

  if (window.history.replaceState) {
    window.history.replaceState(null, null, window.location.href);
  }
  /** ./ variables */

  /** functions */
  /** ./ functions */

  /** initialize */
  $("#shop-slider").slick({
    autoplay: true,
    infinite: true,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    swipe: true,
    centerMode: false,
    autoplaySpeed: 4000,
  });

  $(".slider-reviews").slick({
    autoplay: true,
    infinite: true,
    fade: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    swipe: true,
    centerMode: false,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
  /** ./ initialize */

  /** events */
  $(window).scroll(function () {
    window.pageYOffset > 1
      ? $("#header").addClass("header--scrolled")
      : $("#header").removeClass("header--scrolled");
  });

  $("#top-search-toggle").on("click", () => {
    $("#top-search").toggleClass("active");
  });

  $("#close-top-search").on("click", () => {
    $("#top-search").removeClass("active");
  });

  $(".header__icon").on("click", () => {
    $(".header__menu").toggleClass("header__menu--open");
    $(".icon-hamburger").toggleClass("icon-hamburger--open");
    $(".minicart").removeClass("cart--open");
  });

  $("#back-to-top").on("click", () => {
    $("html, body").animate({ scrollTop: 0 }, 1000);
  });

  var elements_to_watch = document.querySelectorAll(".animate");

  var callback = function (items) {
    items.forEach((item) => {
      if (item.isIntersecting) {
        item.target.classList.add("is-inview");
        observer.unobserve(item.target);
      }
    });
  };

  var observer = new IntersectionObserver(callback, { threshold: 0.35 });

  elements_to_watch.forEach((element) => {
    observer.observe(element);
  });

  $(".cart-icon").on("click", function () {
    $(".minicart").toggleClass("cart--open");

    if ($(".minicart").hasClass("cart--open")) {
      $(".header__menu").removeClass("header__menu--open");
      $(".icon-hamburger").removeClass("icon-hamburger--open");

      $(".megamenu__item").each(function () {
        $(this).removeClass("megamenu__item--active");
      });

      $(".megamenu__submenu").each(function () {
        $(this).removeClass("megamenu__submenu--active");
      });
    }
  });

  $("#close-cart").on("click", () => {
    $(".minicart").removeClass("cart--open");
  });
  /** ./ events */

  /** plugins */
  /** ./ plugins */


})(jQuery);
